import axios, { AxiosError, AxiosResponse } from 'axios';
import getQueryStringParams from './get-query-string-params';

// Create instance
const instance = axios.create();

const urlParams = getQueryStringParams(window.location.search);

const { token } = urlParams;

instance.defaults.params = {
  token,
};

// https://stackoverflow.com/a/45581882/2386601
instance.interceptors.request.use((config) => {
  const configUpdate = config;
  const csrfTokenElement = document.querySelector('meta[name="csrf-token"]');
  const csrfToken = csrfTokenElement
    ? csrfTokenElement.getAttribute('content')
    : '';
  configUpdate.headers.post['x-csrf-token'] = csrfToken;
  configUpdate.headers.put['x-csrf-token'] = csrfToken;
  configUpdate.headers.delete['x-csrf-token'] = csrfToken;
  return configUpdate;
});

instance.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      window.location.href = '/users/sign_out';
    }
    return error;
  },
);

export default instance;
