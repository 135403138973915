import { alpha, createTheme } from '@material-ui/core/styles';
import CheckIconBlue from 'assets/images/check-icon-blue.svg';
import * as fonts from './fonts';

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    extras: {
      seaGreen: string;
    };
    gradients: {
      pinkPeach: string;
      pinkYellow: string;
      seaGreen: string;
    };
    plans: {
      1: string;
      2: string;
      3: string;
      4: string;
      5: string;
      6: string;
      7: string;
      8: string;
      9: string;
      10: string;
      11: string;
      12: string;
    };
  }
  interface PaletteOptions {
    extras: {
      seaGreen: string;
    };
    gradients: {
      pinkPeach: string;
      pinkYellow: string;
      seaGreen: string;
    };
    plans: {
      1: string;
      2: string;
      3: string;
      4: string;
      5: string;
      6: string;
      7: string;
      8: string;
      9: string;
      10: string;
      11: string;
      12: string;
    };
  }
}

declare module '@material-ui/core/styles/createTypography' {
  interface Typography {
    description: TypographyStyleOptions;
    subtitle3: TypographyStyleOptions;
    link: TypographyStyleOptions;
  }
  interface TypographyOptions {
    description: TypographyStyleOptions;
    subtitle3: TypographyStyleOptions;
    link: TypographyStyleOptions;
  }
}

const { typography, breakpoints } = createTheme();
export const { pxToRem } = typography;

const baseTheme = createTheme({
  spacing: 5,
  shape: {
    borderRadius: 0,
  },
  palette: {
    text: {
      primary: '#000000',
      secondary: '#343332',
      hint: '#6B6B6B',
    },
    primary: {
      light: '#8AA6C9',
      main: '#074C93',
    },
    secondary: {
      main: '#E0E0E0',
    },
    success: {
      main: '#219653',
    },
    error: {
      main: '#FF501F',
    },
    grey: {
      100: '#F2F8F9',
      300: '#E0E0E0',
      400: '#D8D8D8',
      800: '#6B6B6B',
      900: '#828282',
    },
    extras: {
      seaGreen: '#AFDCE2',
    },
    gradients: {
      pinkPeach: 'linear-gradient(to right, #FFC1A2, #FFB0BB)',
      pinkYellow: 'linear-gradient(118.72deg, #FFF5F7 39.26%, #FFFBD8 101.66%)',
      seaGreen: 'linear-gradient(269.21deg, #EDF5F6 9.61%, #AFDCE2 192.13%)',
    },
    plans: {
      1: '#F34F4F',
      2: '#88B337',
      3: '#5EC0F6',
      4: '#BB6BD9',
      5: '#FF968C',
      6: '#A87E75',
      7: '#F8D253',
      8: '#FF884D',
      9: '#6AA9FF',
      10: '#FF5E11',
      11: '#DA76CA',
      12: '#0DC18B',
    },
  },
  typography: {
    fontFamily: 'Work Sans, Arial, sans-serif',
    h3: {
      fontFamily: 'CircularMedium, Arial, sans-serif',
      fontVariantLigatures: 'none',
      color: '#000000',
      fontSize: pxToRem(32),
      lineHeight: pxToRem(42),
      marginBottom: 5,

      [breakpoints.down('sm')]: {
        fontSize: pxToRem(22),
        lineHeight: pxToRem(30),
      },
    },
    h4: {
      fontFamily: 'CircularMedium, Arial, sans-serif',
      fontVariantLigatures: 'none',
      color: '#000000',
      fontSize: pxToRem(22),
      lineHeight: pxToRem(32),
      marginBottom: 5,

      [breakpoints.down('sm')]: {
        fontSize: pxToRem(22),
        lineHeight: pxToRem(30),
      },
    },
    body1: {
      color: '#000000',
      fontFamily: 'Work Sans, Arial, sans-serif',
      fontSize: pxToRem(16),
      lineHeight: pxToRem(26),
    },
    body2: {
      color: '#343332',
      fontFamily: 'Work Sans, Arial, sans-serif',
      fontWeight: 500,
      fontSize: pxToRem(16),
      lineHeight: pxToRem(26),

      [breakpoints.down('sm')]: {
        fontSize: pxToRem(15),
        lineHeight: pxToRem(25),
      },
    },
    description: {
      color: '#000000',
      fontFamily: 'Work Sans, Arial, sans-serif',
      fontSize: pxToRem(14),
      lineHeight: pxToRem(16),
    },
    link: {
      color: '#074C93',
      fontSize: pxToRem(13),
      fontWeight: 500,
      lineHeight: pxToRem(25),
    },
    subtitle1: {
      color: '#000000',
      fontFamily: 'Work Sans, Arial, sans-serif',
      fontSize: pxToRem(13),
      lineHeight: pxToRem(25),
    },
    subtitle2: {
      color: '#000000',
      fontFamily: 'Work Sans, Arial, sans-serif',
      fontWeight: 500,
      fontSize: pxToRem(13),
      lineHeight: pxToRem(25),
    },
    subtitle3: {
      color: '#000000',
      fontFamily: 'Work Sans, Arial, sans-serif',
      fontSize: pxToRem(13),
      fontWeight: 400,
      lineHeight: pxToRem(20),
    },
    caption: {
      color: '#6B6B6B',
      display: 'inline-block',
      fontFamily: 'Work Sans, Arial, sans-serif',
      fontWeight: 500,
      fontSize: pxToRem(12),
      lineHeight: pxToRem(16),
    },
    button: {
      color: '#ffffff',
      fontFamily: 'Work Sans, Arial, sans-serif',
      fontWeight: 500,
      fontSize: pxToRem(16),
      lineHeight: 1,
    },
  },
  props: {
    MuiButton: {
      disableRipple: true,
    },
    MuiSvgIcon: {
      fontSize: 'inherit',
    },
    MuiOutlinedInput: {
      notched: false,
    },
    MuiMenu: {
      elevation: 2,
    },
    MuiList: {
      disablePadding: true,
    },
  },
});

const theme = createTheme({
  ...baseTheme,
  overrides: {
    MuiContainer: {
      maxWidthLg: {
        [breakpoints.up('lg')]: {
          maxWidth: 1156, // with padding
        },
      },
      maxWidthXl: {
        [breakpoints.up('xl')]: {
          maxWidth: 1156,
        },
      },
    },
    MuiTypography: {
      body1: {
        '& .MuiTypography-gutterBottom': {
          marginBottom: pxToRem(10),
        },
      },
      body2: {
        '& .MuiTypography-gutterBottom': {
          marginBottom: pxToRem(16),
        },
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: alpha(baseTheme.palette.primary.main, 0.5),
      },
    },
    MuiButton: {
      root: {
        borderRadius: baseTheme.typography.pxToRem(26),
        boxShadow: 'none !important',
        textTransform: 'none',
        padding: `${baseTheme.typography.pxToRem(
          14,
        )} ${baseTheme.typography.pxToRem(30)}`,
      },
      text: {
        color: baseTheme.palette.primary.main,
        fontSize: pxToRem(13),
        fontWeight: 500,
        lineHeight: pxToRem(18),
        padding: `${baseTheme.typography.pxToRem(
          6,
        )} ${baseTheme.typography.pxToRem(10)}`,
        '&:hover': {
          background: 'none',
          textDecoration: 'underline',
        },
        '& .MuiButton-iconSizeMedium > *:first-child': {
          fontSize: baseTheme.typography.pxToRem(14),
        },
      },
      endIcon: {
        marginLeft: baseTheme.typography.pxToRem(5),
      },
      sizeSmall: {
        borderRadius: baseTheme.typography.pxToRem(21),
        fontSize: baseTheme.typography.pxToRem(12),
        padding: `${baseTheme.typography.pxToRem(
          8,
        )} ${baseTheme.typography.pxToRem(30)}`,
      },
      outlinedSecondary: {
        color: baseTheme.palette.grey[900],
        '&:hover, &.Mui-focusVisible': {
          backgroundColor: baseTheme.palette.primary.main,
          color: '#ffffff',
        },
      },
      containedSecondary: {
        color: baseTheme.palette.grey[900],
        '&:hover, &.Mui-focusVisible': {
          backgroundColor: baseTheme.palette.primary.main,
          color: '#ffffff',
        },
      },
      outlinedPrimary: {
        '&:hover, &.Mui-focusVisible': {
          backgroundColor: baseTheme.palette.primary.main,
        },
      },
      containedPrimary: {
        '&:hover, &.Mui-focusVisible': {
          backgroundColor: baseTheme.palette.primary.main,
        },
      },
      textPrimary: {
        background: 'none',
        color: baseTheme.palette.primary.main,
        '&:hover, &.Mui-focusVisible': {
          background: 'none',
          color: baseTheme.palette.primary.main,
        },
      },
      textSecondary: {
        background: 'none',
        color: baseTheme.palette.grey[900],
        '&:hover, &.Mui-focusVisible': {
          background: 'none',
          color: baseTheme.palette.primary.main,
        },
      },
      textSizeLarge: {
        fontSize: baseTheme.typography.pxToRem(13),
        lineHeight: baseTheme.typography.pxToRem(16),
      },
      textSizeSmall: {
        fontSize: baseTheme.typography.pxToRem(11),
        lineHeight: baseTheme.typography.pxToRem(14),
      },
    },
    MuiIcon: {
      root: {
        '& img': {
          display: 'block',
        },
      },
    },
    MuiIconButton: {
      root: {
        color: '#000000',
        opacity: 1,
        padding: baseTheme.typography.pxToRem(8),
      },
      label: {
        fontSize: baseTheme.typography.pxToRem(28),
      },
      colorPrimary: {
        color: '#ffffff',
        backgroundColor: baseTheme.palette.primary.main,
        '&:hover, &.Mui-focusVisible': {
          backgroundColor: baseTheme.palette.primary.main,
        },
      },
      colorSecondary: {
        backgroundColor: baseTheme.palette.secondary.main,
        color: baseTheme.palette.grey[900],
        '&:hover, &.Mui-focusVisible': {
          backgroundColor: baseTheme.palette.primary.main,
          color: '#ffffff',
        },
      },
      sizeSmall: {
        fontSize: baseTheme.typography.pxToRem(24),
      },
    },
    MuiSvgIcon: {
      root: {},
    },
    MuiLink: {
      root: {
        color: baseTheme.palette.primary.main,
        fontSize: baseTheme.typography.pxToRem(13),
        fontWeight: 500,
        lineHeight: baseTheme.typography.pxToRem(25),
      },
    },
    MuiFormGroup: {
      root: {
        marginBottom: baseTheme.spacing(5),

        '& > [class*="MuiGrid-spacing-"]': {
          marginBottom: 0,
          marginTop: 0,
        },
        '&  >[class*="MuiGrid-spacing-"] > .MuiGrid-item': {
          [breakpoints.up('md')]: {
            marginBottom: 0,
            marginTop: 0,
            paddingBottom: 0,
            paddingTop: 0,
          },
        },
      },
      row: {
        width: '100%',
      },
    },
    MuiFormControl: {
      root: {
        marginBottom: baseTheme.spacing(1),
      },
    },
    MuiFormLabel: {
      asterisk: {
        display: 'none',
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: baseTheme.typography.pxToRem(13),
        lineHeight: 1,
        margin: 0,
        marginBottom: baseTheme.spacing(3),
      },
      contained: {
        margin: 0,
        marginBottom: baseTheme.spacing(3),
        marginLeft: 0,
        marginRight: 0,
        marginTop: 0,
      },
    },
    MuiInputBase: {
      root: {
        marginBottom: baseTheme.spacing(2),
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 0,
      },
      input: {
        border: 'none',
        borderRadius: 0,
        boxShadow: '0px 4px 3px -3px #0000001A',
        boxSizing: 'border-box',
        height: baseTheme.typography.pxToRem(63),
        padding: `${baseTheme.typography.pxToRem(
          30,
        )} ${baseTheme.typography.pxToRem(20)} ${baseTheme.typography.pxToRem(
          10,
        )} ${baseTheme.typography.pxToRem(20)}`,
        '&[type=number]': {
          '-moz-appearance': 'textfield',
        },
        '&::-webkit-outer-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0,
        },
        '&::-webkit-inner-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0,
        },
      },
      inputMultiline: {
        border: 'none',
        borderRadius: 0,
        boxShadow: '0px 4px 3px -3px #0000001A',
        boxSizing: 'border-box',
        height: 'initial',
        padding: `${baseTheme.typography.pxToRem(
          30,
        )} ${baseTheme.typography.pxToRem(20)} ${baseTheme.typography.pxToRem(
          10,
        )} ${baseTheme.typography.pxToRem(20)}`,
      },
      multiline: {
        padding: 0,
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          background: 'none',
        },
      },
      selectMenu: {
        borderRadius: 0,
        height: baseTheme.typography.pxToRem(63),
        padding: `${baseTheme.typography.pxToRem(
          30,
        )} ${baseTheme.typography.pxToRem(20)} ${baseTheme.typography.pxToRem(
          10,
        )} ${baseTheme.typography.pxToRem(20)}`,
        '&:focus': {
          background: 'none',
        },
      },
    },
    MuiPaper: {
      root: {
        background: '#ffffff',
        boxShadow: '0px 4px 3px -3px rgba(0, 0, 0, 0.1)',
        position: 'relative',
      },
      elevation1: {
        boxShadow: '0px 4px 3px -3px rgba(0, 0, 0, 0.1)',
      },
    },
    MuiMenu: {
      paper: {
        border: `1px solid ${baseTheme.palette.secondary.main}`,
        boxShadow: 'box-shadow: 0px 4px 3px -3px #0000001A',
        marginTop: baseTheme.spacing(1),
        padding: `${baseTheme.typography.pxToRem(20)} 0`,
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: baseTheme.typography.pxToRem(16),
        lineHeight: baseTheme.typography.pxToRem(26),
        '&:last-child': {
          marginBottom: 0,
        },
        '&$selected': {
          '&::after': {
            display: 'inline-flex',
            content: '""',
            backgroundImage: `url('${CheckIconBlue}')`,
            backgroundSize: `${baseTheme.typography.pxToRem(
              18,
            )} ${baseTheme.typography.pxToRem(13)}`,
            height: baseTheme.typography.pxToRem(13),
            marginLeft: baseTheme.spacing(3),
            width: baseTheme.typography.pxToRem(18),
          },
        },
      },
    },
    MuiListItem: {
      root: {
        paddingBottom: baseTheme.spacing(3),
        paddingTop: 0,

        '&:last-child': {
          paddingBottom: 0,
        },
      },
      gutters: {
        paddingLeft: baseTheme.spacing(7),
        paddingRight: baseTheme.spacing(7),
      },
      button: {
        '&$selected': {
          background: 'none',
          fontWeight: 500,
        },
        '&:hover': {
          background: 'none !important',
        },
      },
    },
    MuiChip: {
      label: {
        color: '#ffffff',
        fontWeight: 500,
      },
      sizeSmall: {
        paddingLeft: baseTheme.typography.pxToRem(16),
        paddingRight: baseTheme.typography.pxToRem(16),
      },
      labelSmall: {
        fontSize: baseTheme.typography.pxToRem(12),
        lineHeight: baseTheme.typography.pxToRem(23),
        padding: '0 !important',
      },
    },
    MuiSlider: {
      markLabel: {
        ...baseTheme.typography.caption,
        marginTop: baseTheme.typography.pxToRem(28),
        [breakpoints.down('sm')]: {
          textAlign: 'center',
          whiteSpace: 'break-spaces',
          width: baseTheme.typography.pxToRem(80),
          fontSize: baseTheme.typography.pxToRem(12),
          marginTop: baseTheme.typography.pxToRem(20),
        },
      },
      thumb: {
        width: baseTheme.typography.pxToRem(28),
        height: baseTheme.typography.pxToRem(28),
        marginTop: baseTheme.typography.pxToRem(-8),
        marginLeft: baseTheme.typography.pxToRem(-10),
        background: baseTheme.palette.gradients.pinkYellow,
        boxShadow: '0px 0px 6px 2px #0000001A',
        border: `1px solid ${baseTheme.palette.grey[400]}`,
        '&$focusVisible, &$active, &:hover': {
          boxShadow: '0px 0px 2px 5px #0000001A',
        },
      },
      rail: {
        height: baseTheme.typography.pxToRem(12.48),
        border: `1px solid ${baseTheme.palette.grey[400]}`,
        opacity: 1,
        boxShadow: '0px 4px 3px -3px #0000001A',
        borderRadius: baseTheme.typography.pxToRem(11),
        backgroundColor: '#F2F2F2',
      },
      track: {
        height: baseTheme.typography.pxToRem(11),
        border: `1px solid ${baseTheme.palette.grey[400]}`,
        opacity: 1,
        boxShadow: '0px 4px 3px -3px #0000001A',
        borderRadius: baseTheme.typography.pxToRem(11),
        background: baseTheme.palette.gradients.pinkYellow,
      },
      valueLabel: {
        left: 'calc(-50% + 12px)',
        '& > span': {
          background: 'transparent',
          '& > span': {
            color: '#000',
            ...baseTheme.typography.h3,
          },
        },
      },
      mark: {
        height: baseTheme.typography.pxToRem(8),
        width: baseTheme.typography.pxToRem(8),
        backgroundColor: baseTheme.palette.grey[400],
        borderRadius: baseTheme.typography.pxToRem(8),
        marginTop: baseTheme.typography.pxToRem(2.5),
        '&[style="left: 0%;"]': {
          marginLeft: baseTheme.typography.pxToRem(3),
        },
        '&[style="left: 100%;"]': {
          marginLeft: baseTheme.typography.pxToRem(-9),
        },
      },
      markActive: {
        backgroundColor: 'transparent',
        border: 'none',
      },
    },
    MuiCssBaseline: {
      '@global': {
        '@font-face': [{ ...fonts.workSans, fontDisplay: 'swap' }],
        body: {
          background: baseTheme.palette.gradients.seaGreen,
        },
        p: {
          color: baseTheme.palette.text.secondary,
          fontFamily: 'Work Sans, Arial, sans-serif',
          fontWeight: 500,
          fontSize: baseTheme.typography.pxToRem(16),
          lineHeight: baseTheme.typography.pxToRem(26),

          [breakpoints.down('sm')]: {
            fontSize: baseTheme.typography.pxToRem(15),
            lineHeight: baseTheme.typography.pxToRem(25),
          },
        },
        strong: {
          fontWeight: 500,
        },
        '.MuiAutocomplete-popper': {
          marginBottom: baseTheme.spacing(1),
          marginTop: baseTheme.spacing(1),

          '& .MuiAutocomplete-paper': {
            border: `1px solid ${baseTheme.palette.secondary.main}`,
            boxShadow: '0px -3px 4px 0px #0000001A',
            padding: baseTheme.spacing(4),
            margin: 0,
          },
          '& .MuiAutocomplete-listbox': {
            padding: 0,
          },
          '& .MuiAutocomplete-option': {
            display: 'block',
            paddingLeft: baseTheme.spacing(2),
            paddingRight: baseTheme.spacing(2),
            position: 'relative',
            '&[aria-selected="true"]': {
              background: 'none',
              paddingRight: baseTheme.spacing(4),
              '&::after': {
                display: 'inline-flex',
                content: '""',
                backgroundImage: `url('${CheckIconBlue}')`,
                backgroundSize: `${baseTheme.typography.pxToRem(
                  18,
                )} ${baseTheme.typography.pxToRem(13)}`,
                height: baseTheme.typography.pxToRem(13),
                position: 'absolute',
                right: baseTheme.spacing(2),
                top: 'calc(50% - 14px)',
                width: baseTheme.typography.pxToRem(18),
              },
            },
            '&[data-focus="true"]': {
              background: 'none',
            },
            '&:hover': {
              background: 'none !important',
            },
          },
        },
        '.MuiAlert-root': {
          borderRadius: `${baseTheme.typography.pxToRem(15)} !important`,
          marginBottom: baseTheme.spacing(3),
          marginTop: baseTheme.spacing(3),
          padding: `${baseTheme.typography.pxToRem(
            15,
          )} ${baseTheme.typography.pxToRem(20)} !important`,

          '& .MuiAlert-icon': {
            marginRight: baseTheme.spacing(3),
          },
          '&.MuiAlert-standardError': {
            background: 'rgba(255, 80, 31, 0.05)',
            border: `2px solid ${baseTheme.palette.error.main}`,

            '& .MuiAlert-message': {
              color: baseTheme.palette.error.main,
            },
          },
          '&.MuiAlert-standardInfo': {
            border: `2px solid ${baseTheme.palette.info.main}`,

            '& .MuiAlert-message': {
              color: baseTheme.palette.info.main,
            },
          },
          '&.MuiAlert-standardWarning': {
            border: `2px solid ${baseTheme.palette.warning.main}`,

            '& .MuiAlert-message': {
              color: baseTheme.palette.warning.main,
            },
          },
          '&.MuiAlert-standardSuccess': {
            border: `2px solid ${baseTheme.palette.success.main}`,

            '& .MuiAlert-message': {
              color: baseTheme.palette.success.main,
            },
          },
          '& .MuiAlert-message': {
            fontSize: 13,
            lineHeight: baseTheme.typography.pxToRem(20),
          },
          '& .MuiAlert-icon, & .MuiAlert-message': {
            padding: 0,
          },
          '& .MuiFormHelperText-root': {
            display: 'block',
            margin: 0,
          },
        },
      },
    },
    MuiCheckbox: {
      colorPrimary: {
        background: 'transparent',
        '&:hover': {
          background: 'transparent !important',
        },
      },
    },
  },
});

export default theme;
