import { DefaultTheme } from 'styled-components/macro';
import { nayyaStyles } from './nayyaThemeStyles';

export const upwiseStyles: DefaultTheme = {
  typography: {
    ...nayyaStyles.typography,
    h1: {
      fontFamily: 'Laca, sans-serif',
      color: '#250E62',
    },
    body1: {
      fontFamily: 'Noto Sans, sans-serif',
      color: '#250E62',
    },
    body2: {
      color: '#7C6EA1',
    },
    links: {
      back: {
        color: '#504BE1',
        fontFamily: 'Noto Sans, sans-serif',
        fontWeight: 700,
        border: '1.3px solid #504BE1',
        borderRadius: '40px',
      },
    },
    disclaimer: {
      ...nayyaStyles.typography.disclaimer,
      color: '#513e81',
      fontFamily: 'Noto Sans, sans-serif',
      letterSpacing: '0.01em',
    },
  },
  buttons: {
    ...nayyaStyles.buttons,
    primary: {
      backgroundColor: '#504BE1',
      radius: '40px',
    },
  },
  miscellaneous: {
    ...nayyaStyles.miscellaneous,
    border: '1px solid #D3CFE0',
    borderRadius: '6px',
  },
};
