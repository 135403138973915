import axios from 'helpers/axios';
import { AuthTypes } from 'types/auth.types';

type CheckIsValidEmailProps = (
  email: string,
  setIsValidEmail: (isTrue: boolean) => void,
) => void;

// check if email input is valid
export const checkIsValidEmail: CheckIsValidEmailProps = (
  email,
  setIsValidEmail,
) => {
  const re = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;

  if (re.test(email)) {
    setIsValidEmail(true);
  } else {
    setIsValidEmail(false);
  }
};

// handle SSO sign-in/sign-up for existing user
const handleSsoAuth = (data: DataProps[]) => {
  if (data[0].provider && data[0].provider === 'saml') {
    window.location.replace(
      `${window.origin}/users/auth/saml?idp_uuid=${data[0].idp_uuid}`,
    );
  } else if (data[0].adp_redirect_url) {
    window.location.href = data[0].adp_redirect_url;
  }
};

// handle non-SSO sign-in for existing user
const handleNonSsoUser = async (email: string) => {
  const url = '/users/confirmation';

  const response = await axios.post(url, { email });
  const { data } = response;

  return data;
};

// function to check for url parameter
const checkParams = (param: string) => {
  const results = new URLSearchParams(
    document.location.search.substring(1),
  ).get(param);

  if (results === null) {
    return '';
  }

  return results;
};

// handles non-SSO sign-up for new users
const handleNonSsoSignUp = async (email: string): Promise<void> => {
  const employer = checkParams('employer');
  const employeeClass = checkParams('class');

  const url = '/send_verification_email';

  await axios.post(url, {
    email,
    employer,
    class: employeeClass,
  });
};

interface DataProps {
  provider?: string;
  idp_name?: string;
  idp_uuid?: string;
  company?: string;
  adp_redirect_url?: string;
}

// delegate existing user to correct sign-in/sign-up process
export const delegateValidEmail = async (
  ssoData: DataProps[],
  email: string,
  type: AuthTypes,
): Promise<{
  showLoadingScreen: boolean;
  mfaEnabledAtSignin: boolean;
}> => {
  let mfaEnabledAtSignin = false;
  let showLoadingScreen = false;
  let openAccessSignUp = false;
  const employer = checkParams('employer');
  const employeeClass = checkParams('class');

  if (employer && employeeClass) {
    openAccessSignUp = true;
  }

  if (ssoData && ssoData.length > 0 && !openAccessSignUp) {
    handleSsoAuth(ssoData);
    showLoadingScreen = true;
  } else if (type === AuthTypes.SIGN_IN) {
    const data = await handleNonSsoUser(email);
    if (data === true) {
      mfaEnabledAtSignin = true;
    }
  } else if (type === AuthTypes.SIGN_UP) {
    handleNonSsoSignUp(email);
  }

  return { showLoadingScreen, mfaEnabledAtSignin };
};
