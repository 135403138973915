import axios from 'axios';
import { compactHash } from 'helpers/hash-utils';
import { InvitationSubmitProps } from './types';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const invitationSubmit = async ({
  submitUrl,
  authenticityToken,
  useSignUp,
  formParams,
}: InvitationSubmitProps) => {
  try {
    const validFormParams = compactHash(formParams);
    const response = await axios.put(
      submitUrl,
      {
        authenticity_token: authenticityToken,
        sign_up: useSignUp,
        user: {
          ...validFormParams,
        },
        ...validFormParams,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      },
    );

    const { data } = response;

    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw error;
  }
};
