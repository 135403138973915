import React from 'react';
import { LineContainer, Line, Text, PresentationContainer } from './styles';
import { CenteredTextLineProps } from './types';

const CenteredTextLine: React.FC<CenteredTextLineProps> = ({ text }) => {
  return (
    <PresentationContainer>
      <LineContainer>
        <Line />
        <Text>{text}</Text>
        <Line />
      </LineContainer>
    </PresentationContainer>
  );
};

export default CenteredTextLine;
