import React from 'react';
import { useTranslate } from 'react-polyglot';
import { Container, DisclaimerText } from './styles';

export const UpwiseDisclaimerText: React.FC = () => {
  const t = useTranslate();

  return (
    <Container>
      <DisclaimerText>{t('points.upwise.disclaimer')}</DisclaimerText>
    </Container>
  );
};
