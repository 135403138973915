import styled from 'styled-components/macro';
import { Line as BaseLine, Text as BaseText } from '../styles';

export const Line = styled(BaseLine)`
  background-color: #e9e7ef;
  color: #e9e7ef;
`;

export const Text = styled(BaseText)`
  font-family: 'Noto Sans', sans-serif;
`;
