import React, { MouseEvent } from 'react';
import Arrow from 'assets/images/upwise/right-arrow.svg';
import Logo from 'assets/images/upwise/theme-logo.svg';
import {
  BackArrow,
  BackArrowContainer,
} from 'pages/auth-screens/SignInPassword/upwise/styles';
import { LegalText } from 'pages/auth-screens/LegalText/upwise/styles';
import { UpwiseLegalText } from 'pages/auth-screens/LegalText/upwise/LegalText';
import { MagicLinkEmailSentProps } from '../types';
import {
  BackText,
  DidntReceiveEmail,
  EmailSentText,
  MarginLogo,
} from '../styles';
import { Container, Header } from './styles';

export const MagicLinkEmailSent = ({
  email,
  setError,
  setPassword,
  setEmail,
  goBack,
}: MagicLinkEmailSentProps): JSX.Element => {
  const handleBackButton = (
    e: MouseEvent<HTMLImageElement> | MouseEvent<HTMLDivElement>,
  ) => {
    e.preventDefault();
    setEmail('');
    setError('');
    setPassword('');
    goBack();
  };
  return (
    <>
      <Container>
        <BackArrowContainer onClick={handleBackButton}>
          <BackArrow src={Arrow} />
          <BackText>Back to entering your email</BackText>
        </BackArrowContainer>
        <MarginLogo src={Logo} />
        <Header>An email is on the way</Header>
        <EmailSentText>
          We sent an email to {email} with a password-free link that will log
          you in right away.
        </EmailSentText>

        <DidntReceiveEmail>
          Didn&apos;t receive your email? Check your spam folder or{' '}
          <a href="/users/sign_in">try another email address</a>.
        </DidntReceiveEmail>
        <UpwiseLegalText />
      </Container>
    </>
  );
};
