/* eslint-disable react/jsx-props-no-spreading */
import { CircularProgress, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { useTranslate } from 'react-polyglot';
import { INayyaButtonProps, NayyaButton } from '../Button';

export interface INayyaLoaderButtonProps extends INayyaButtonProps {
  isLoading?: boolean;
}

const useStyles = makeStyles<Theme>((theme) => ({
  loadingButtonWrapper: {
    position: 'relative',
  },
  buttonSuccess: {
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
  buttonProgress: {
    color: theme.palette.success.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const LoaderButton = ({
  children,
  isLoading,
  disabled,
  ...rest
}: INayyaLoaderButtonProps): JSX.Element => {
  const classes = useStyles();
  const t = useTranslate();

  return (
    <div className={classes.loadingButtonWrapper}>
      <NayyaButton
        type="submit"
        variant="contained"
        color="primary"
        disabled={disabled || isLoading}
        {...rest}
      >
        {children || t('buttons.next')}
      </NayyaButton>
      {isLoading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </div>
  );
};

export default LoaderButton;
export { LoaderButton as NayyaLoaderButton };
