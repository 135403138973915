export const isValidEmail = (input: string): boolean => {
  const re = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;
  return re.test(input);
};
export const isDateValid = (date: string): boolean => {
  const dateRegex = /^(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])(19|20)\d{2}$/;
  return date === '' ? true : dateRegex.test(date);
};

export const isPhoneNumberValid = (phoneNumber: string): boolean => {
  const phoneRegex = /^\d{10}$/;
  return phoneNumber === '' ? true : phoneRegex.test(phoneNumber);
};
