import axios from 'axios';
import { SendVerificationEmailProps } from './types';

export const sendPasswordResetEmail = async ({
  email,
  authenticityToken,
}: SendVerificationEmailProps): Promise<void> => {
  await axios.post(
    `/users/password`,
    {
      user: { email },
      authenticity_token: authenticityToken,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    },
  );
};
