export enum Themes {
  DEFAULT = 'default',
  UPWISE = 'upwise',
}

export type SupportedThemes = {
  theme: Themes.DEFAULT | Themes.UPWISE;
};

export enum AuthTypes {
  SIGN_IN = 'SIGN_IN',
  SIGN_UP = 'SIGN_UP',
  VERIFICATION = 'VERIFICATION',
}

export enum Steps {
  EMAIL = 'EMAIL',
  PASSWORD = 'PASSWORD',
  VERIFICATION = 'VERIFICATION',
  LOADING = 'LOADING',
}
