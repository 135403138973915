export const useCsrf = (): {
  csrfToken: string | null;
  csrfTokenElement: Element | null;
} => {
  const csrfTokenElement = document.querySelector('meta[name="csrf-token"]');
  const csrfToken = csrfTokenElement
    ? csrfTokenElement.getAttribute('content')
    : '';

  return { csrfToken, csrfTokenElement };
};
