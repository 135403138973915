import React from 'react';
import styled from 'styled-components/macro';
import { Themes } from 'types/auth.types';

const UpwiseP = styled.p`
  font-family: 'Noto Sans', sans-serif;
  a {
    font-family: 'Noto Sans', sans-serif;
  }
`;

const CreateAccountPrompt = ({
  themeBrandName,
  theme,
}: {
  themeBrandName: string;
  theme?: Themes;
}): JSX.Element => {
  const content = (
    <>
      New to {themeBrandName}?
      <a href={`${window.location.origin}/users/sign_up`}>
        {' '}
        Create an account.
      </a>
    </>
  );

  if (theme === Themes.UPWISE) {
    return <UpwiseP>{content}</UpwiseP>;
  }

  return <p>{content}</p>;
};

export { CreateAccountPrompt };
