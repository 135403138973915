import getRequiredServiceTerms, {
  RequiredServiceTerms,
} from 'helpers/get-required-service-terms';
import getToken from 'helpers/get-token';
import { useCustomTheme } from 'providers/CustomThemeProvider';
import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  FooterContainer,
  Col,
  FooterInner,
  Text,
  List,
  Item,
  Link,
} from './AuthFooter/styles';

const AuthFooter = (): JSX.Element => {
  const { theme } = useCustomTheme();

  const isUpwise = theme === 'upwise';

  const [requiredServiceTerms, setRequiredServiceTerms] =
    useState<RequiredServiceTerms | null>(null);
  const token = getToken(window.location.search);

  const supportURL = isUpwise
    ? 'https://support.upwise.com/hc/en-us'
    : 'https://support.nayya.com/hc/en-us';

  useEffect(() => {
    let isAuthFooterMounted = true;
    const fetchData = async () => {
      const data = await getRequiredServiceTerms(token);
      if (isAuthFooterMounted) {
        setRequiredServiceTerms(data);
      }
    };
    fetchData();
    return () => {
      isAuthFooterMounted = false;
    };
  }, [token]);

  return (
    <FooterContainer>
      <Col>
        <FooterInner>
          <Text>© Nayya Health, Inc. All rights reserved.</Text>
          {requiredServiceTerms && (
            <List>
              <Item>
                <Link
                  href={requiredServiceTerms.privacy_policy_content}
                  target="_blank"
                >
                  Privacy Policy
                </Link>
              </Item>
              <Item>
                <Link
                  href={requiredServiceTerms.end_user_content}
                  target="_blank"
                >
                  Terms of Service
                </Link>
              </Item>
              <Item>
                <Link href={supportURL} target="_blank">
                  Help
                </Link>
              </Item>
            </List>
          )}
        </FooterInner>
      </Col>
    </FooterContainer>
  );
};

export default AuthFooter;
