import axios from 'helpers/axios';
import getRequiredServiceTerms from 'helpers/get-required-service-terms';
import getToken from 'helpers/get-token';
import * as React from 'react';
import { FormEvent, useEffect, useState } from 'react';
import { AuthTypes, Steps, Themes } from 'types/auth.types';
import { useCustomTheme } from 'providers/CustomThemeProvider';
import AuthInputContainer from './AuthInputContainer';
import Loading from './Loading';
import { checkIsValidEmail, delegateValidEmail } from './MultiFactorAuth';
import { StyledForm } from './SharedStyledComponents';
import { AlreadyHaveAccountPrompt as UpwiseAccountPrompt } from './AlreadyHaveAccountPrompt/upwise/AlreadyHaveAccountPrompt';
import { AlreadyHaveAccountPrompt } from './AlreadyHaveAccountPrompt/AlreadyHaveAccountPrompt';

interface ISignUpEmailProps {
  nextStep: () => void;
  defaultEmail?: string;
}

const SignUpEmail = ({
  nextStep,
  defaultEmail = '',
}: ISignUpEmailProps): JSX.Element => {
  const { theme } = useCustomTheme();

  const [step, setStep] = useState<Steps>(Steps.EMAIL);
  const [email, setEmail] = useState(defaultEmail);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [requiredServiceTermsChecked, setRequiredServiceTermsChecked] =
    useState(false);
  const [requiredServiceTerms, setRequiredServiceTerms] = useState(null);
  const token = getToken(window.location.search);

  const handleSignUp = async (e: FormEvent | null) => {
    if (e) e.preventDefault();

    if (!isValidEmail) return;

    const authType = 'SIGN_UP';
    const url = '/sso_options';
    const response = await axios.post(url, { email, authType });
    const { data } = response;
    const result = await delegateValidEmail(data, email, AuthTypes.SIGN_UP);

    if (result.showLoadingScreen) {
      setStep(Steps.LOADING);
    } else {
      nextStep();
    }
  };

  useEffect(() => {
    async function fetchData() {
      const data = await getRequiredServiceTerms(token);
      setRequiredServiceTerms(data);
    }
    fetchData();
  }, [token]);

  useEffect(() => {
    checkIsValidEmail(email, setIsValidEmail);
  }, [email]);

  return (
    <StyledForm>
      {step === 'EMAIL' && (
        <AuthInputContainer
          authType={AuthTypes.SIGN_UP}
          email={email}
          isValidEmail={isValidEmail}
          requiredServiceTermsChecked={requiredServiceTermsChecked}
          requiredServiceTerms={requiredServiceTerms}
          handleClick={handleSignUp}
          setEmail={setEmail}
          setRequiredServiceTermsChecked={setRequiredServiceTermsChecked}
          theme={theme}
        >
          {theme === Themes.UPWISE ? (
            <UpwiseAccountPrompt />
          ) : (
            <AlreadyHaveAccountPrompt />
          )}
        </AuthInputContainer>
      )}

      {step === 'LOADING' && <Loading />}
    </StyledForm>
  );
};

export default SignUpEmail;
