import * as React from 'react';
import styled from 'styled-components/macro';

const LoadingContainer = styled.div`
  width: 398px;
  height: 100%;
  box-sizing: border-box;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoadingSpinner = styled.div`
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  border-radius: 50%;
  width: 125px;
  height: 125px;
  border: 3px solid #dcdcdc;
  border-top: 3px solid #fff;
  animation: 1500ms spinner linear infinite;

  @media (max-width: 575px) {
    width: 100px;
    height: 100px;
  }
`;

const Loading = (): JSX.Element => {
  return (
    <LoadingContainer>
      <LoadingSpinner />
    </LoadingContainer>
  );
};

export default Loading;
