import styled from 'styled-components/macro';

export const PresentationContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const LineContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 300px;
  align-self: center;
`;

export const Line = styled.div`
  flex-grow: 1;
  height: 1px;
  background-color: black;
  width: 150px;
`;

export const Text = styled.div`
  margin: 0 10px;
  white-space: nowrap;
  font-family: 'Inter', sans-serif;
`;
