import axios from 'helpers/axios';
import getConsentUrls from 'helpers/get-consent-urls';

export interface RequiredServiceTerms {
  privacy_policy_content: string;
  end_user_content: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const getRequiredServiceTerms = async (token: string | null) => {
  const consentUrls = getConsentUrls(token);
  const response = await axios.post(consentUrls.getTermsContent);
  const { data } = response;

  return data;
};
export default getRequiredServiceTerms;
