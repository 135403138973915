import axios from 'axios';
import { ResetPasswordProps } from './types';

export const resetPassword = async ({
  token,
  password,
  passwordConfirmation,
  authenticityToken,
}: ResetPasswordProps) => {
  try {
    const response = await axios.put(
      `/users/password`,
      {
        authenticity_token: authenticityToken,
        user: {
          reset_password_token: token,
          password,
          password_confirmation: passwordConfirmation,
        },
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      },
    );

    const { data } = response;

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
