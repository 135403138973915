import React from 'react';
import { CenteredTextLineProps } from '../types';
import { LineContainer } from '../styles';
import { Line, Text } from './styles';

const CenteredTextLine: React.FC<CenteredTextLineProps> = ({ text }) => {
  return (
    <LineContainer>
      <Line />
      <Text>{text}</Text>
      <Line />
    </LineContainer>
  );
};

export default CenteredTextLine;
