import React from 'react';
import { I18n } from 'react-polyglot';
import { Themes } from 'types/auth.types';
import get from 'lodash/get';
import { CustomThemeProvider } from 'providers/CustomThemeProvider';
import muiTheme from 'theme/index';
import { MuiThemeProvider, ThemeProvider } from '@material-ui/core';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

const ApplicationProviders = ({
  children,
  theme,
}: {
  children: React.ReactNode;
  theme?: Themes;
}): JSX.Element => {
  const locale = get(window, 'locale') || 'en';
  const phrases = get(window, 'gon.phrases') || {};

  const customTheme =
    Themes[theme?.toUpperCase() as keyof typeof Themes] || Themes.DEFAULT;

  return (
    <I18n locale={locale} messages={phrases}>
      <QueryClientProvider client={queryClient}>
        <MuiThemeProvider theme={muiTheme}>
          <ThemeProvider theme={muiTheme}>
            <CustomThemeProvider theme={customTheme}>
              {children}
            </CustomThemeProvider>
          </ThemeProvider>
        </MuiThemeProvider>
      </QueryClientProvider>
    </I18n>
  );
};

export { ApplicationProviders };
