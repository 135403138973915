import React from 'react';
import { useTranslate } from 'react-polyglot';
import { Container, LegalText } from './styles';

export const UpwiseLegalText: React.FC = () => {
  const t = useTranslate();

  return (
    <Container>
      <LegalText>{t('points.upwise.legal')}</LegalText>
    </Container>
  );
};
