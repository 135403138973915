import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  width: 100%;

  @media (max-width: 575px) {
    margin-top: 0;
    padding: 0 16px;
  }
`;

export const LegalText = styled.p`
  ${({ theme }) => {
    const styles = theme.typography.disclaimer;
    return `
      font-family: ${styles.fontFamily};
      line-height: ${styles.lineHeight};
      color: ${styles.color};
      font-weight: ${styles.fontWeight};
      letter-spacing: ${styles.letterSpacing};
      `;
  }}
  font-size: 12px;
  text-align: center;
`;
