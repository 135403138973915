import React from 'react';

const AlreadyHaveAccountPrompt = (): JSX.Element => {
  return (
    <p>
      Already have an account?
      <a href={`${window.location.origin}/users/sign_in`}> Log in.</a>
    </p>
  );
};

export { AlreadyHaveAccountPrompt };
