import * as React from 'react';
import { useState } from 'react';
import { AuthTypes } from 'types/auth.types';
import { useCustomTheme } from 'providers/CustomThemeProvider';
import AuthInputContainer from './AuthInputContainer';
import PointsContainer from './PointsContainer';
import SignUpEmail from './SignUpEmail';

const heading =
  "We've sent a verification email to the email address you provided.";

const SignUpForm = (): JSX.Element => {
  const { theme } = useCustomTheme();
  const [step, setStep] = useState(1);

  const nextStep = () => setStep(step + 1);

  const stepOneComponents = () => {
    return (
      <>
        <PointsContainer theme={theme} />
        <SignUpEmail nextStep={nextStep} />
      </>
    );
  };

  const stepTwoComponents = () => {
    return (
      <>
        <PointsContainer theme={theme} />
        <AuthInputContainer
          theme={theme}
          authType={AuthTypes.VERIFICATION}
          heading={heading}
        />
      </>
    );
  };

  switch (step) {
    case 1:
      return stepOneComponents();

    case 2:
      return stepTwoComponents();

    default:
      return <></>;
  }
};

export default SignUpForm;
