import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* 398px PointsContainer width - 384px Fields width */
  /* 782: sum of two columns */
  max-width: 782px;
  margin-top: 24px;

  @media (max-width: 575px) {
    margin-top: 0;
    padding: 0 16px;
  }
`;

export const DisclaimerText = styled.p`
  ${({ theme }) => {
    const styles = theme.typography.disclaimer;
    return `
      font-family: ${styles.fontFamily};
      line-height: ${styles.lineHeight};
      color: ${styles.color};
      font-weight: ${styles.fontWeight};
      letter-spacing: ${styles.letterSpacing};
      `;
  }}
  font-size: 12px;
  text-align: center;
`;
