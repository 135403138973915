const getQueryStringParams = (query: string): Record<string, string> => {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split('&')
        .reduce((params: Record<string, string>, param) => {
          const [key, value] = param.split('=');
          // eslint-disable-next-line no-param-reassign
          params[key] = value
            ? decodeURIComponent(value.replace(/\+/g, ' '))
            : '';
          return params;
        }, {})
    : {};
};
export default getQueryStringParams;
