/* eslint-disable react/jsx-props-no-spreading */
import { CircularProgress } from '@mui/material';
import React from 'react';
import { useTranslate } from 'react-polyglot';
import styled from 'styled-components/macro';

const Button = styled.button<{ disabled: boolean; outlined?: boolean }>`
  height: 46px;
  border-radius: 40px;
  width: 300px;
  background-color: #504be1;
  color: white;
  font-weight: 700;
  font-family: 'Noto Sans', sans-serif;
  margin: 0;

  &:hover {
    background-color: #3b38a6;
  }

  ${({ disabled }) =>
    disabled &&
    `
    background-color: #D3CFE0;
    color: #white;

    &:hover {
      background-color: #D3CFE0;
    }

  `}

  ${({ outlined }) =>
    outlined &&
    `
    background-color: white;
    border: 1px solid #504be1;
    color: #504be1;

    &:hover {
      background-color: #2F06A6;
      color: white;
      border-color: #2F06A6;
    }
    `}
`;

const Container = styled.div`
  position: relative;
`;

const UpwiseButton = ({
  children,
  disabled,
  id,
  isLoading,
  onClick,
  outlined,
  ...rest
}: {
  children: React.ReactNode;
  disabled?: boolean;
  id: string;
  isLoading?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  outlined?: boolean;
}): JSX.Element => {
  const t = useTranslate();

  return (
    <Container>
      <Button
        id={id}
        onClick={onClick}
        disabled={!!(disabled || isLoading)}
        outlined={outlined}
        {...rest}
      >
        {children || t('buttons.next')}
      </Button>
      {isLoading && (
        <CircularProgress
          size={24}
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
          }}
        />
      )}
    </Container>
  );
};

export default UpwiseButton;
