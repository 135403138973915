const getURLParams = (queryString: string) => {
  const urlParams = new URLSearchParams(queryString);
  return urlParams;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const getToken = (queryString: string) => {
  const params = getURLParams(queryString);
  const token = params.get('token');
  return token;
};

export default getToken;
