import styled from 'styled-components/macro';

export const LogInContainer = styled.div<{
  tokenExpired?: boolean;
  authWithMagicLink?: boolean;
}>`
  background-color: white;
  padding: ${({ tokenExpired, authWithMagicLink }) =>
    // eslint-disable-next-line no-nested-ternary
    tokenExpired
      ? '24px 24px 67px 24px'
      : authWithMagicLink
      ? '36px 24px 67px 24px'
      : '72px 24px 67px 24px'};

  text-align: center;

  h5 {
    padding-bottom: 10px;
    line-height: 1.63;
    height: 26px;
    font-size: 16px;
    font-family: 'Work Sans', sans-serif;
    color: #343332;
  }

  p {
    line-height: 1.63;
    height: 20px;

    a {
      color: #2f80ed;
      text-decoration: none;
    }
  }

  @media (min-width: 575px) {
    height: 100%;
  }

  @media (max-width: 575px) {
    h5 {
      margin-bottom: 20px;
    }
  }
`;

export const PasswordContainer = styled.div`
  position: relative;
  text-align: right;

  @media (max-width: 575px) {
    width: 296px;
    margin: 0 auto;
  }
  @media (max-width: 374px) {
    width: 280px;
    margin: 0 auto;
  }
`;

export const PasswordInput = styled.input`
  display: block;
  margin: auto;
  border: 0;
  outline: none;
  border-bottom: 2px solid #074c93;
  padding-bottom: 5px;
  width: 296px;
  height: 24px;
  font-size: 16px;
  font-family: 'Work Sans', sans-serif;
  line-height: 1.5;
  letter-spacing: -0.32px;

  &::-ms-reveal,
  &::-ms-clear {
    display: none;
  }

  @media (max-width: 575px) {
    margin: 0 0 10px;
  }

  @media (max-width: 374px) {
    width: 280px;
  }
`;

export const PasswordToggle = styled.img`
  position: absolute;
  right: 27px;
  width: 25px;
  bottom: 7px;
  user-select: none;

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 575px) {
    font-size: 13px;
  }
`;

export const ErrorMessage = styled.p`
  color: red;
  font-size: 13px;
  margin: 0;
`;

export const WarningMessage = styled.p`
  color: #ff85a2 !important;
  font-size: 13px;
  margin: 0px 0px 0px 26px !important;
  min-height: 20px !important;
  text-align: left;
`;

export const BackArrowContainer = styled.div`
  display: flex;
  padding-bottom: 42px;
  font-family: 'Work Sans', sans-serif;
  font-size: 14px;

  &:hover {
    cursor: pointer;
  }
`;

export const BackArrow = styled.img`
  transform: rotate(180deg);
  transition: transform 0.2s;
  margin-right: 5px;

  &:hover {
    cursor: pointer;
    transform: rotate(180deg) scale(1.5);
  }
`;

export const ButtonContainer = styled.div`
  button {
    @media (min-width: 575px) {
      margin: 36px 0 24px;
    }

    @media (max-width: 374px) {
      width: 272px !important;
    }
  }
`;

export const ButtonStyles = {
  margin: '36px auto 24px',
  width: '300px',
  height: '50px',
  borderRadius: '6px',
  fontSize: '16px',
  fontWeight: 600,
};

export const LogInWIthoutPasswordButton = {
  margin: '10px auto 4px',
  width: '300px',
  height: '50px',
  borderRadius: '6px',
  fontSize: '16px',
  fontWeight: 600,
  backgroundColor: '#ffffff',
  color: '#003184',
  fontFamily: 'Inter, sans-serif',
  border: '1px solid #003184',
  letterSpacing: '-0.5px',
};

export const ExpiredTokenContainer = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  display: flex
  flex-direction: column;
  align-content: center;
  justify-content: center;
  height: 32px;
  width: 100%;
  background-color: #FCF1ED;
  color: #1D1D1B;
  border-radius: 4px;
`;

export const EmailGreeting = styled.h5<{ isLongEmail: boolean }>`
  margin-bottom: ${({ isLongEmail }) => (isLongEmail ? '20px' : '0')};
`;
