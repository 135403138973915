import styled from 'styled-components/macro';

export const LogInContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 30px 24px 48px 24px;
  text-align: center;
  font-family: 'Noto Sans', sans-serif;

  h5 {
    padding-bottom: 10px;
    line-height: 1.63;
    height: 26px;
    font-size: 16px;
    font-family: 'Noto Sans', sans-serif;
    color: #250e62;
  }

  p {
    line-height: 1.63;
    height: 26px;
    color: #250e62;

    a {
      color: #504be1;
      text-decoration: none;
    }
  }

  @media (min-width: 575px) {
    height: 100%;
  }

  @media (max-width: 575px) {
    padding-top: 74px;

    h5 {
      margin-bottom: 10px;
    }
  }
`;

export const ExpiredTokenContainer = styled.div`
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  align-content: center;
  justify-content: center;
  padding: 8px 0px;
  width: 100%;
  background-color: #f0f0ff;
  color: #250e62;
  border-radius: 4px;
`;

export const UpwisePasswordInput = styled.input`
  border: 1px solid #e0e0de;
  border-radius: 4px;
  height: 58px;
  display: block;
  margin: auto;
  outline: none;
  padding: 12px;
  width: 296px;
  font-size: 16px;
  font-family: 'Noto Sans', sans-serif;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.32px;
  color: #7c6ea1;

  &::-ms-reveal,
  &::-ms-clear {
    display: none;
  }

  @media (max-width: 575px) {
    margin: 0 0 10px;
  }

  @media (max-width: 374px) {
    width: 280px;
  }
`;

export const PasswordToggle = styled.img`
  position: absolute;
  right: 27px;
  margin-right: 10px;
  width: 25px;
  top: 50%;
  transform: translateY(-50%);
  user-select: none;

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 575px) {
    font-size: 13px;
    top: unset;
    transform: unset;
    bottom: 29px;
  }
`;

export const ButtonContainer = styled.div`
  button {
    margin: 24px 0;

    @media (max-width: 374px) {
      width: 272px !important;
    }
  }
`;

export const BackArrowContainer = styled.div`
  display: flex;
  padding-bottom: 20px;
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  color: '#250e62';

  &:hover {
    cursor: pointer;
  }
`;

export const BackArrow = styled.img`
  transition: transform 0.2s;
  margin-right: 5px;

  &:hover {
    cursor: pointer;
    transform: scale(1.5);
  }
`;
