export const workSansMedium = {
  fontFamily: 'Work Sans, Arial, sans-serif',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 500,
};
export const workSans = {
  fontFamily: 'Work Sans, Arial, sans-serif',
  fontStyle: 'normal',
  fontWeight: 400,
};
