const CONSENT_ENDPOINT = '/consent_to_end_user_and_privacy_terms';
const REMOVE_ENDPOINT = '/remove_consent_to_end_user_and_privacy_terms';
const CHECK_ENDPOINT = '/user_has_required_consents';
const GET_TERMS_CONTENT_ENDPOINT =
  '/get_active_and_required_service_terms_content';

type Urls = {
  remove: string;
  consent: string;
  check: string;
  getTermsContent: string;
};

const getConsentUrls = (token?: string | null): Urls => {
  const tokenAddon: string = token ? `?token=${token}` : '';
  return {
    remove: `${REMOVE_ENDPOINT}${tokenAddon}`,
    consent: `${CONSENT_ENDPOINT}${tokenAddon}`,
    check: `${CHECK_ENDPOINT}${tokenAddon}`,
    getTermsContent: `${GET_TERMS_CONTENT_ENDPOINT}${tokenAddon}`,
  };
};

export default getConsentUrls;
