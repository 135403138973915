import styled from 'styled-components/macro';

export const StyledForm = styled.form`
  @media (min-width: 576px) {
    width: 398px;
  }
  @media (max-width: 575px) {
    height: 550px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`;
