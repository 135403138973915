import axios from 'axios';

interface SendMagicLinkEmailProps {
  email: string;
  authenticityToken: string;
}

export const sendMagicLinkEmail = async ({
  email,
  authenticityToken,
}: SendMagicLinkEmailProps) => {
  const response = await axios.post(
    '/users/passwordless/sign_in',
    {
      user: { email },
      authenticity_token: authenticityToken,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    },
  );

  const { data } = response;
  return data;
};
