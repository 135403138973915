import { DefaultTheme } from 'styled-components/macro';

export const nayyaStyles: DefaultTheme = {
  typography: {
    h1: {
      fontFamily: 'Circular, sans-serif',
      color: '#1D1D1B',
    },
    body1: {
      fontFamily: 'Inter, sans-serif',
      color: '#1D1D1B',
    },
    body2: {
      color: '#73736C',
    },
    links: {
      back: {
        color: '#003184',
        fontFamily: 'Inter, sans-serif',
        fontWeight: 600,
        border: '1.3px solid #003184',
        borderRadius: '4px',
      },
    },
    footer: {
      fontFamily: 'Inter, sans-serif',
      color: '#73736C',
    },
    disclaimer: {
      color: '#73736C',
      fontFamily: 'Inter, sans-serif',
      fontWeight: 400,
      lineHeight: '20px',
    },
  },
  buttons: {
    primary: {
      backgroundColor: '#064C93',
      radius: '6px',
    },
    disabled: {
      backgroundColor: '#C5C5C1',
    },
  },
  miscellaneous: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #E0E0DE',
    borderDanger: '1px solid #FF0000',
    borderRadius: '4px',
  },
};
