import React from 'react';
import { Paragraph } from './styles';

const AlreadyHaveAccountPrompt = (): JSX.Element => {
  return (
    <Paragraph>
      Already have an account?
      <a href={`${window.location.origin}/users/sign_in`}> Log in.</a>
    </Paragraph>
  );
};

export { AlreadyHaveAccountPrompt };
