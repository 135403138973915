import * as React from 'react';
import { FormEvent } from 'react';
import { AuthTypes } from 'types/auth.types';
import { useCustomTheme } from 'providers/CustomThemeProvider';
import AuthInputContainer from './AuthInputContainer';
import { CreateAccountPrompt } from './CreateAccountPrompt';

interface ISignInEmailProps {
  email: string;
  isValidEmail: boolean;
  handleCheckSso: (
    e: FormEvent | null,
    isFirstTimeCheck: boolean | undefined,
  ) => void;
  setEmail: (email: string) => void;
  rememberedEmail: boolean;
  setRememberedEmail: (save: boolean) => void;
}

const SignInEmail = ({
  email,
  isValidEmail,
  handleCheckSso,
  setEmail,
  setRememberedEmail,
  rememberedEmail,
}: ISignInEmailProps): JSX.Element => {
  const { theme, themeBrandName } = useCustomTheme();
  return (
    <AuthInputContainer
      authType={AuthTypes.SIGN_IN}
      email={email}
      isValidEmail={isValidEmail}
      requiredServiceTermsChecked
      handleClick={handleCheckSso}
      setEmail={setEmail}
      setRememberedEmail={setRememberedEmail}
      rememberedEmail={rememberedEmail}
      theme={theme}
    >
      <CreateAccountPrompt themeBrandName={themeBrandName} theme={theme} />
    </AuthInputContainer>
  );
};

export default SignInEmail;
