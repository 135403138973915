import React, { createContext, useContext, useEffect, useState } from 'react';
import { Themes } from 'types/auth.types';
import { ThemeProvider } from 'styled-components/macro';
import { nayyaStyles } from './nayyaThemeStyles';
import { upwiseStyles } from './upwiseThemeStyles';

const CustomThemeContext = createContext({
  theme: Themes.DEFAULT,
  themeBrandName: 'Nayya',
  themeObject: nayyaStyles,
});

const getThemeBrandName = (theme: Themes) => {
  switch (theme) {
    case Themes.DEFAULT:
      return 'Nayya';
    case Themes.UPWISE:
      return 'Upwise';
    default:
      return 'Nayya';
  }
};

const getThemeObject = (theme: Themes) => {
  switch (theme) {
    case Themes.UPWISE:
      return upwiseStyles;
    default:
      return nayyaStyles;
  }
};

const CustomThemeProvider = ({
  children,
  theme,
}: {
  children: React.ReactNode;
  theme: Themes;
}): React.ReactElement => {
  const [themeBrandName, setThemeBrandName] = useState(
    getThemeBrandName(theme),
  );

  const [themeObject, setThemeObject] = useState(getThemeObject(theme));

  useEffect(() => {
    setThemeBrandName(getThemeBrandName(theme));
    setThemeObject(getThemeObject(theme));
  }, [theme]);

  return (
    <CustomThemeContext.Provider value={{ theme, themeBrandName, themeObject }}>
      <ThemeProvider theme={themeObject}>{children}</ThemeProvider>
    </CustomThemeContext.Provider>
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useCustomTheme = () => {
  const context = useContext(CustomThemeContext);
  if (context === undefined) {
    throw new Error('useCustomTheme must be used within a CustomThemeProvider');
  }
  return context;
};

export default CustomThemeProvider;
export { CustomThemeContext, CustomThemeProvider, useCustomTheme };
